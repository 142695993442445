import {graphql, Link} from 'gatsby'
import React from 'react'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

const IndexPage = ({data}) => (
  <Layout>
    <Seo title="de of het, welk lidwoord? Vind het antwoord hier." />
    <h1>de of het, welk lidwoord?</h1>
    <p>
      In het Nederlands bestaan drie lidwoorden: de <em>bepaalde</em> lidwoorden{' '}
      <strong>de</strong> en <strong>het</strong> en het <em>onbepaald</em>{' '}
      lidwoord <strong>een</strong>. Er zijn weinig regels te geven voor het
      gebruik van de of het.
    </p>
    <p>
      Op deze site kun er je snel achter komen welk lidwoord bij een zelfstandig
      naamwoord hoort.
    </p>
    <h2>De meest gezochte woorden:</h2>
    <ul>
      {data.allMongodbDeofhetWords.edges.map(({node}) => (
        <li key={node.id}>
          <Link to={node.slug}>de of het {node.name}?</Link>
        </li>
      ))}
    </ul>
    <h3>Bepalende lidwoorden, de en het</h3>
    <p>
      De en het zijn <em>bepalende</em> (ook wel: bepaalde) lidwoorden.{' '}
      <strong>Het</strong> wordt gebruikt bij onzijdige woorden in het
      enkelvoud, en <strong>de</strong> bij mannelijke en vrouwelijke woorden en
      bij meervouden.
    </p>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMongodbDeofhetWords(
      filter: {featured: {eq: true}, active: {eq: true}}
      limit: 8
    ) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`
